<template>
  <div :class="showType ? '' : 'container'">
    <van-sticky :offset-top="0">
      <div style="display: flex;height: 3rem; border-bottom: 1px #DDD solid; background-color: white;" v-if="showType">
        <div style="height: 3rem; line-height: 3rem;padding-left: 0.5rem;padding-top: 0.1rem;">
          <van-icon size="1.4rem" name="arrow-left" @click="goback" />
        </div>
        <van-search v-model="value" placeholder="搜索商品" style="width: 100%;" shape="round" background="#FFF"
          @search="seach" @focus="seach1">
        </van-search>
        <div style="width: 15%;
    text-align: center;height: 3rem; line-height: 3rem;" @click="seach">搜索</div>
      </div>
      <head-child v-else class="head" :change="true">
        <template #left>
          <div class="flex jcc t-a-c" v-if="boo2" @click="Switch">
            <div class="ellipsis mr-5">
              {{ address }}
            </div>
            <div>
              <i class="iconfont icon-iconfontarrows1"></i>
            </div>
          </div>
          <div v-else @click="goback">
            <van-icon size="1.5rem" name="arrow-left" />
          </div>
        </template>
        <template #middle>
          <div>
            <van-search ref="field" v-model="value" placeholder="搜素商品" @focus="seach1" shape="round"  @search="seach" 
              style="background-color: rgb(255, 255, 255)" />
          </div>
        </template>
        <template #right>
          <div @click="seach">搜索</div>
        </template>
      </head-child>
      <div class="top-type" v-if="showSelect">
        <div @click="setNomal" class="top-left" :style="{ color: priceType ? '#333' : '#F00' }"> 默认推荐</div>
        <div class="line"></div>
        <div @click="setPrictType" class="top-right"> 价格 <van-image style="margin-left: 0.2rem;" width="0.6rem"
            height="0.8rem" :src="priceType ? priceType == '1' ? priceup : pricedown : priceno" />
        </div>
      </div>
    </van-sticky>

    <seachview ref="seach" :value="value" :type="2" @showtop="showTop" :id="id" :hotType="hotType"></seachview>

  </div>
</template>

<script>
import { Toast } from "vant";
import HeadChild from "../components/HeadChild";
import Seachview from "../components/index/Seachview.vue";
let initViewfinish = false
export default {
  name: 'SearchGoods',
  data() {
    return {
      priceup: require('@/assets/images/price-up.png'),
      pricedown: require('@/assets/images/price-down.png'),
      priceno: require('@/assets/images/price-no.png'),
      priceType: null,//null 默认推荐  1 升序  0 降序  
      value: '',
      address: '',
      seachvalue: '',
      boo2: true,
      showSelect: false,
      id: null,
      showType: null,
      hotType: null,
    }
  },
  created() { },
  components: { HeadChild, Seachview },
  methods: {
    showTop() {
      this.showSelect = true
    },
    setPrictType() {
      if (this.priceType) {
        if (this.priceType == '1') {
          this.priceType = '0'
        } else {
          this.priceType = '1'
        }
      } else {
        this.priceType = '1'
      }
      this.$refs.seach.setPriceType(this.priceType)
    },
    setNomal() {
      this.priceType = null
      this.$refs.seach.setPriceType(this.priceType)
    },
    seach1() {
      this.boo2 = false
    },
    seach() {
      this.$refs.seach.seach(this.value);
    },
    goback() {
      this.$router.back()
    },
    Switch() {
      this.$router.push("SelectAdderss");
    },
    initView() {
      this.id = this.$route.query.id
      this.hotType = this.$route.query.hotType
      this.showType = this.$route.query.showType
      this.value = ''
      this.address = localStorage.getItem("address");
      this.seachvalue = ''
      this.boo2 = true
      this.$nextTick(() => {
        this.$refs.seach.reset();
      })
    },
  },
  mounted() {
  },
  beforeRouteEnter(to, from, next) {
    console.log('from/path', from.path);
    to.meta.keepAlive = true
    if (from.path !== "/Details") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView()
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      initViewfinish = true
      next();
    }
  },
  created() {
    if (initViewfinish) {
      initViewfinish = false
      this.initView()
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0
    }
  },
}

</script>
<style lang="scss" scoped>
.head {
  padding: 0.5rem 0.2rem;
  // height: 2rem;
  color: white;
}


.top-type {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: white;
  height: 3rem;
  align-items: center;
  margin-bottom: 0.5rem;

  .top-left {
    flex: 1;
    text-align: center;
  }

  .line {
    background-color: #DDD;
    height: 1.5rem;
    width: 1px;
  }

  .top-right {
    flex: 1;
    text-align: center;
  }
}

.container {
  background-image: url("../assets/images/home_back.jpg");
  background-size: 100% 12rem;
  background-repeat: no-repeat;
}

.van-search {
  background-color: rgb(255, 255, 255, 0);
}
</style>